import { createListenerMiddleware, isFulfilled } from '@reduxjs/toolkit';

import { showNotification } from 'services/globalSlice';

// Combined endpoints with custom messages or a boolean to indicate the presence of a server provided message
const endpoints = {
  forgotPassword: { message: 'appNotifications:success.forgotPassword', autoHideDuration: 15000 },
  resetPassword: { message: 'appNotifications:success.resetPassword', autoHideDuration: 15000 },
  shareRoute: { message: 'appNotifications:success.shareRoute', autoHideDuration: 15000 },
  // Add a default handler for generic success cases if needed
  // For example: default: { message: 'Operation successful!', autoHideDuration: 5000 },
};

export const rtkSuccessListenerMiddleware = createListenerMiddleware();

rtkSuccessListenerMiddleware.startListening({
  matcher: isFulfilled,
  effect: async (action, { dispatch }) => {
    const endpointName = action?.meta?.arg?.endpointName;
    const successConfig = endpoints[endpointName] || (endpointName === 'updateProfile' && {
      message: Object.keys(action?.meta?.arg?.originalArgs).includes('oldPassword')
        ? 'appNotifications:success.passwordUpdate'
        : 'appNotifications:success.profileUpdate',
      autoHideDuration: 5000,
    });

    // If there's a custom success message or a generic message for updateProfile
    if (successConfig) {
      dispatch(showNotification({
        ...successConfig,
        type: 'success',
      }));
    }
  },
});
