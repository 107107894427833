import { lazy } from 'react';

import { createBrowserRouter, redirect } from 'react-router-dom';

import { userApi } from 'services/user';
import { store } from 'store';

// Lazy-load the components
const AppLayout = lazy(() => import(/* webpackChunkName: "AppLayout" */ 'layouts/AppLayout'));
const AuthLayout = lazy(() => import(/* webpackChunkName: "AuthLayout" */ 'layouts/AuthLayout'));
const GlobalLayout = lazy(() => import(/* webpackChunkName: "GlobalLayout" */ 'layouts/GlobalLayout'));
const Account = lazy(() => import(/* webpackChunkName: "Account" */ 'views/app/Account'));
const Optimize = lazy(() => import(/* webpackChunkName: "Optimize" */ 'views/app/Optimize'));
const ForgotPassword = lazy(() => import(/* webpackChunkName: "ForgotPassword" */ 'views/auth/ForgotPassword'));
const Login = lazy(() => import(/* webpackChunkName: "Login" */ 'views/auth/Login'));
const Register = lazy(() => import(/* webpackChunkName: "Register" */ 'views/auth/Register'));
const ResetPassword = lazy(() => import(/* webpackChunkName: "ResetPassword" */ 'views/auth/ResetPassword'));

/* eslint-disable no-constant-condition */

const resetPasswordLoader = async ({ request }) => {
  const url = new URL(request.url);
  const resetKey = url.searchParams.get('resetKey');
  if (!resetKey) {
    // If there's no resetKey, redirect to login
    return redirect('/login', { replace: true });
  }
  // If resetKey is present, allow access to the route
  return { resetKey };
};

const authLoader = async () => {
  const user = store.dispatch(userApi.endpoints.getUser.initiate());
  const { data /*...*/ } = await user;

  const params = new URLSearchParams(window.location.search);
  const to = params.get('from') || '/';
  // maybe use the following, so it doesn't make a new request?
  // const user = api.endpoints.getUser.select()(state)
  // const { data, isSuccess, isError, error } = user
  if (data?.accountDetails?.emailAddress) {
    return redirect(to);
  }
  return to;
};

export const protectedLoader = async ({ request = {} }) => {
  const user = store.dispatch(userApi.endpoints.getUser.initiate());
  const { data /*...*/ } = await user;
  // maybe use the following, so it doesn't make a new request?
  // const user = api.endpoints.getUser.select()(state)
  // const { data, isSuccess, isError, error } = user
  if (!data?.accountDetails?.emailAddress) {
    let params = new URLSearchParams();
    params.set('from', new URL(request?.url).pathname);
    return redirect('/login?' + params.toString());
  }
  return null;
};

export const router = createBrowserRouter([
  {
    id: 'root',
    Component: GlobalLayout,
    children: [
      {
        id: 'app',
        loader: protectedLoader,
        Component: AppLayout,
        children: [
          {
            index: true,
            Component: Optimize,
          },
          {
            path: 'account',
            Component: Account,
          },
        ],
      },
      {
        id: 'auth',
        Component: AuthLayout,
        loader: authLoader,
        children: [
          {
            id: 'auth-forgot-password',
            path: 'forgot-password',
            Component: ForgotPassword,
          },
          {
            id: 'auth-register',
            path: 'register',
            Component: Register,
          },
          {
            id: 'auth-reset-password',
            path: 'reset-password',
            loader: resetPasswordLoader,
            Component: ResetPassword,
          },
          {
            id: 'auth-login',
            path: 'login',
            Component: Login,
          },
        ],
      },
    ],
  },
  {
    path: '/logout',
    async action() {
      // We signout in a "resource route" that we can hit from a fetcher.Form
      // await fakeAuthProvider.signout();
      // return redirect('/');
    },
  },
  {
    path: '*', // Matches any path not matched by the above
    loader: () => {
      // Redirect to the home page ('/')
      return redirect('/');
    },
  },
]);
