import { createTheme } from '@mui/material/styles';

// Create a theme instance.
export const theme = createTheme({
  palette: {
    primary: {
      main: '#0D2932',
    },
    secondary: {
      main: '#42A1BA',
    },
    tertiary: {
      main: '#FFF200',
      contrastText: '#0D2932',
    },
    quaternary: {
      light: 'rgb(115, 204, 228)',
      main: '#50c0de',
    },
    text: {
      primary: '#0D2932',
      secondary: '#3D4A5F',
    },
  },
});
