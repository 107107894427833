import { createSlice } from '@reduxjs/toolkit';

const notificationOptionsInitialState = {
  message: '',
  open: false,
  type: null,
};

export const globalSliceInitialState = {
  isSubscriptionModalOpen: false,
  lastDisplayedFreeAccountSubscriptionModalMilliseconds: 0,
  notificationOptions: notificationOptionsInitialState,
};

export const globalSlice = createSlice({
  name: 'global',
  initialState: globalSliceInitialState,
  reducers: {
    closeNotification: (state) => {
      state.notificationOptions = notificationOptionsInitialState;
    },
    setLastDisplayedFreeAccountSubscriptionModalMilliseconds: (state, action) => {
      state.lastDisplayedFreeAccountSubscriptionModalMilliseconds = action.payload;
    },
    setSubscriptionModalOpen: (state, action) => {
      state.isSubscriptionModalOpen = action.payload;
    },
    showNotification: (state, action) => {
      state.notificationOptions = {
        ...action.payload,
        open: true,
      };
    },
  },
});

export const {
  closeNotification,
  setLastDisplayedFreeAccountSubscriptionModalMilliseconds,
  setSubscriptionModalOpen,
  showNotification,
} = globalSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`

export default globalSlice.reducer;
