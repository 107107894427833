import { createListenerMiddleware, isFulfilled } from '@reduxjs/toolkit';

import { configureGAUserId, getGAClientId, trackAnalytics } from 'common/analytics';
import { toTitleCase } from 'common/toTitleCase';
import { userApi } from 'services/user';

export const rtkAnalyticsListenerMiddleware = createListenerMiddleware();

rtkAnalyticsListenerMiddleware.startListening({
  matcher: isFulfilled,
  effect: async (action, { dispatch }) => {
    switch (action?.meta?.arg?.endpointName) {
      case 'login':
        trackAnalytics({ eventName: 'login' });
        configureGAUserId(action.payload.accountDetails.id);
        getGAClientId((gaClientId) => {
          dispatch(userApi.endpoints.updateUser.initiate({ analyticsId: gaClientId }));
        });
        break;
      case 'register':
        trackAnalytics({ eventName: 'sign_up' });
        configureGAUserId(action.payload.accountDetails.id);
        getGAClientId((gaClientId) => {
          dispatch(userApi.endpoints.updateUser.initiate({ analyticsId: gaClientId }));
        });
        break;
      case 'getUser':
        configureGAUserId(action.payload.accountDetails.id);
        break;
      case 'optimizeRoute':
        trackAnalytics({
          eventName: 'optimizeRoute',
          parameters: {
            event_category: 'Routes',
            event_label: 'Optimize Route',
            travel_mode: action.meta.arg.originalArgs.travelMode,
            num_waypoints: action.meta.arg.originalArgs.waypoints.length,
            original_distance: action.payload.originalRoute.intervalValues.distanceMeters,
            original_duration: action.payload.originalRoute.intervalValues.durationSeconds,
            optimized_distance: action.payload.optimizedRouteTotals.distanceMeters,
            optimized_duration: action.payload.optimizedRouteTotals.durationSeconds,
            distance_delta: action.payload.optimizationDeltas.distanceMeters,
            duration_delta: action.payload.optimizationDeltas.durationSeconds,
            num_routes: action.payload.totalRoutes,
          },
        });
        break;
      case 'cancelSubscription':
        trackAnalytics({
          eventName: 'subscription_cancellation',
          parameters: {
            event_category: 'User Subscription',
            event_label: 'Cancel Subscription',
            subscription_type: toTitleCase(action.meta.arg.originalArgs.lookupKey.replace('_', ' - ')),  // the type of subscription being canceled
            change_effective_date: action.meta.arg.originalArgs.periodEndDate.split('T')[0],
          },
        });
        break;
      case 'updateSubscription':
        trackAnalytics({
          eventName: 'subscription_change',
          parameters: {
            event_category: 'User Subscription',
            event_label: 'Change Subscription',
            old_subscription_type: toTitleCase(action.meta.arg.originalArgs.oldLookupKey.replace('_', ' - ')),
            new_subscription_type: toTitleCase(action.meta.arg.originalArgs.newLookupKey.replace('_', ' - ')),
          },
        });
        break;
      default:
        break;
    }
  },
});
