import posthog from 'posthog-js';

export const trackAnalytics = (
  { eventName, parameters = {}, trackMicrosoft = false, trackPosthog = true, type = 'event' },
) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'prod' && eventName) {
    if (window.gtag) {
      window.gtag(
        type,
        eventName,
        { ...parameters },
      );
    }

    if (trackPosthog) {
      posthog.capture(eventName, { ...parameters });
    }

    // if (trackMicrosoft) {
    //   window.uetq = window.uetq || [];
    //   window.uetq.push(
    //     type,
    //     eventName,
    //     { ...parameters },
    //   );
    // }
  } else {
    console.log(
      'DEBUG Analytics Event', {
        type,
        eventName,
        parameters: { ...parameters },
      },
    );
  }
};

export const configureGAUserId = (userId) => {
  if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    if (window.gtag) {
      window.gtag('config', process.env.REACT_APP_GA_TAG, { 'user_id': userId });
    }
  }
};

export const getGAClientId = (fn) => {
  if (window.gtag) {
    return window.gtag('get', process.env.REACT_APP_GA_TAG, 'client_id', (field) => {fn(field);});
  }
};
