import { createTransform } from 'redux-persist';

import { globalSliceInitialState } from 'services/globalSlice';

export const globalSliceTransform = createTransform(
  // transform state on its way to being serialized and persisted.
  ({ lastDisplayedFreeAccountSubscriptionModalMilliseconds }, key) => (
    { lastDisplayedFreeAccountSubscriptionModalMilliseconds }
  ),
  // transform state being rehydrated
  ({ lastDisplayedFreeAccountSubscriptionModalMilliseconds }, key) => (
    { ...globalSliceInitialState, lastDisplayedFreeAccountSubscriptionModalMilliseconds }
  ),
  { whitelist: ['global'] },
);
