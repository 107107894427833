import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';

import { router } from 'router';
import { persistor, store } from 'store';
import { theme } from 'theme';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <RouterProvider fallbackElement={<div>Loading...</div>} router={router} />
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
