/**
 * Converts a string to title case, capitalizing the first letter of each word.
 *
 * @param {string} str - The string to convert to title case.
 * @returns {string} The converted string with each word's first letter in uppercase and the rest in lowercase.
 */

export const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase(),
  );
